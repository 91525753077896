'use strict';

angular.module('informaApp')
    .directive('infConnectedPieCharts', ["PieChartHelper", function (PieChartHelper) {
            return {
                restrict: 'E',
                templateUrl: 'directives/inf-connected-pie-charts/template.ptl.html',
                scope: {
                    leftTitle: "@?",
                    rightTitle: "@?",
                    leftNoDataMsg: "@?",
                    rightNoDataMsg: "@?",
                    source: "=",
                    onRender: "=",
                    colorPattern: "="
                },
                render: function (scope, data, element) {
                    if (data){
                        var canvasElement = element.find('.charty')[0];

                        element.find(".charty").empty();

                        var chart = new PieChartHelper.Chart(canvasElement, {
                            width: 250,
                            height: 250,
                            leftTitle: scope.leftTitle,
                            rightTitle: scope.rightTitle,
                            leftNoDataMsg: scope.leftNoDataMsg,
                            rightNoDataMsg: scope.rightNoDataMsg,
                            leftData: data.left,
                            rightData: data.right,
                            onRendered: scope.onRender,
                            colorPattern: scope.colorPattern
                        });
                    }
                },
                link: function (scope, element, attr) {
                    var _this = this;

                    scope.$watch("source", function (newValue) {
                        _this.render(scope, newValue, element);
                    });
                }
            }
        }]);
